<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_details')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.vehicleType"
                            :error-messages="vehicleTypeErrors"
                            :label="$t('vechile_type')"
                                  dense
                                    outlined
                            required
                            @input="$v.editedData.vehicleType.$touch()"
                            @blur="$v.editedData.vehicleType.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.vehicleCategory"
                            :error-messages="vehicleCategoryErrors"
                            :label="$t('vehicle_category')"
                            required
                            @input="$v.editedData.vehicleCategory.$touch()"
                                  dense
                                    outlined
                            @blur="$v.editedData.vehicleCategory.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.vehicleModelNumber"
                            :error-messages="vehicleModelNumberErrors"
                            :label="$t('vehicle_model_number')"
                            required
                                  dense
                                    outlined
                            @input="$v.editedData.vehicleModelNumber.$touch()"
                            @blur="$v.editedData.vehicleModelNumber.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.vehicleNumber"
                            :label="$t('vehicle_number')"
                            required
                                  dense
                                    outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.vehicleRegistrationNumber"
                            :label="$t('vehicle_registration_number')"
                            required
                                  dense
                                    outlined
                            type="number"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.seatNumber"
                            :label="$t('seat_number')"
                            required
                                  dense
                                    outlined
                            type="number"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", "serviceID"],
        computed: {
            vehicleTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.vehicleType.$dirty) return errors;
                !this.$v.editedData.vehicleType.required &&
                errors.push("vehicle type is required");
                return errors;
            },
            vehicleCategoryErrors() {
                const errors = [];
                if (!this.$v.editedData.vehicleCategory.$dirty) return errors;
                !this.$v.editedData.vehicleCategory.required &&
                errors.push("vehicle category is required.");
                return errors;
            },
            vehicleModelNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.vehicleModelNumber.$dirty) return errors;
                !this.$v.editedData.vehicleModelNumber.required &&
                errors.push("vehicle model number is required.");
                return errors;
            },
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                vehicleType: {required},
                vehicleCategory: {required},
                vehicleModelNumber: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
                let serviceData = await axios.get(`Transportation/GetTransportationDetailByIDAsync/${this.serviceID}`);
                this.editedData = serviceData.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        transportationID: this.editedData.transportationID,
                        vehicleType: this.editedData.vehicleType,
                        vehicleModelNumber: this.editedData.vehicleModelNumber,
                        vehicleCategory: this.editedData.vehicleCategory,
                        vehicleNumber: this.editedData.vehicleNumber,
                        vehicleRegistrationNumber: this.editedData.vehicleRegistrationNumber,
                        seatNumber: parseInt(this.editedData.seatNumber)
                    };
                    axios.post("Transportation/UpdateTransportationDetailAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Details added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.vehicleType = "";
                this.editedData.vehicleCategory = "";
            }
        }
    };
</script>

<style scoped>
</style>